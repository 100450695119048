import React from 'react';
import clsx from 'clsx';

import styles from './base_secure.module.scss';
import LanguageSelector, {
  defaultSupportedLanguages,
} from '../../UI/LanguageSelector';
import LucetLogo from '../../UI/LucetLogo';

const BaseSecure = ({
  mode,
  children,
  locale = 'en',
  handleChangeLocale,
  bodyClasses = '',
}) => {
  const isLive = mode === 'live'; // mode options case be view, edit or live

  return (
    <div
      className={clsx(
        styles.baseSecureWrap,
        'lucet-logic-pages',
        isLive ? 'is-live' : 'is-preview',
      )}
    >
      <div className={clsx(styles.baseSecureInner, 'base-secure-inner')}>
        <div
          className={clsx(
            styles.secureMessageBodyWrapper,
            'secure-body-wrapper',
          )}
        >
          <div className={clsx(styles.logoWrapper, 'bf-login-header')}>
            <div className={styles.imgWrapper}>
              <LucetLogo
                type="secure_message"
                wrapperClasses="symphony-logo-wrapper"
              />
            </div>
          </div>
          <div className={clsx(styles.wrapperContent, 'bf-login-detail')}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <LanguageSelector
              wraperClasses={clsx(styles.languageSelector, 'selector')}
              currentLocale={locale}
              supportedLanguages={defaultSupportedLanguages}
              onChange={(newLocale) => {
                handleChangeLocale(newLocale);
              }}
            />
            <div
              className={clsx(styles.secureBodyWrapper, 'bf-login-detail-body')}
            >
              <div className={clsx(styles.content, bodyClasses)}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseSecure;
