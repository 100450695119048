import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import styles from './manage_communication_preferences.module.scss';
import useFilterDataAsUrl from '../../../../hooks/useFilterDataAsUrl';
import useLoader from '../../../../context/utilities/useLoader';
import CommunicationPreferenceForm from './CommunicationPreferenceForm';
import BaseSecure from '../../../../components/custom_messages/base_templates/BaseSecure';
import { LanguageTranslator } from '../../../../LanguageTranslator';
import { updateLocale } from '../../../../helperFunctions';

const FormWrapper = () => {
  const [, params] = useFilterDataAsUrl();
  const { patient_id: patientID } = params || {};
  const [locale, setLocale] = useState();
  const { setLoader } = useLoader();
  const translated = useMemo(() => {
    if (locale) {
      return LanguageTranslator.getTranslations(locale);
    }
    return {};
  }, [locale]);

  const updateLocaleTo = (newLocale) => {
    setLocale(newLocale);
    updateLocale(newLocale);
  };

  return (
    <BaseSecure
      mode="live"
      locale={locale}
      handleChangeLocale={(newLocale) => updateLocaleTo(newLocale)}
      bodyClasses={clsx(styles.comWrapper, 'lucet-brand-modal')}
    >
      <h1 className={styles.comHeaderTitle}>
        {translated.notification_preferences}
      </h1>
      <CommunicationPreferenceForm
        patientID={patientID}
        setLoader={setLoader}
        locale={locale}
        setLocale={setLocale}
        isPatientFacing
        allowTextNotification
      />
    </BaseSecure>
  );
};

export default FormWrapper;
